import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";

import Logo from "./media/logo_lanchonete.png";
import Leaf from "./media/landingpage_elementos-04.png";
import Fleur from "./media/landingpage_elementos-06.png";

ReactDOM.render(
  <React.StrictMode>
    <main className="main-content">
      {/* <div className="Bar Bar--top" />
      <div className="Bar Bar--left" />
      <div className="Bar Bar--bottom" />
      <div className="Bar Bar--right" />
      <img className="Home-leaf Home-leaf--top-left" src={Leaf} alt="" />
      <img className="Home-leaf Home-leaf--top-right" src={Leaf} alt="" />
      <img className="Home-leaf Home-leaf--bottom-left" src={Leaf} alt="" />
<img className="Home-leaf Home-leaf--bottom-right" src={Leaf} alt="" />*/}
      <img className="Home-leaf Home-leaf--top-left" src={Leaf} alt="" />
      <img className="Home-leaf Home-leaf--bottom-right" src={Fleur} alt="" />
      <div className="Home-block">
        <div className="Logo-wrapper">
          <img className="Logo" src={Logo} alt="" />
        </div>
        <div className="Buttons-wrapper">
          <a
            className="button button--pandora"
            href="https://alanchonete.eatbu.com/"
          >
            <span>Belém</span>
          </a>

          <a
            className="button button--pandora button--last"
            href="https://alanchonetebenfica.eatbu.com/"
          >
            <span>Benfica</span>
          </a>
          <a
            className="button button--pandora button--last"
            href="https://alanchoneteparede.eatbu.com/"
          >
            <span>Parede</span>
          </a>
        </div>
      </div>
    </main>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
